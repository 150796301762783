<template>
  <div class="spectrum-map">
    <!-- :height="getCarouselHeight" -->
    <el-carousel :height="isHeight" class="my-spectrogram-carousel">
      <el-carousel-item v-for="(item, index) in spectrogramList" :key="index">
        <img class="spectrogram-img" :src="uploadImgs + item.img" alt="" />
        <div class="tips" v-if="item.tip" v-html="item.tip"></div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    spectrogramList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isHeight: "98vh",
    };
  },
  computed: {
    ...mapState({
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  methods: {
    listenerSize() {
      const that = this;
      window.addEventListener("resize", function () {
        that.isHeight = window.innerHeight - 62 + "px";
      });
    },
  },
};
</script>

<style lang="less" scoped>
.spectrum-map {
  width: 100%;
  height: 100%;
  padding: 30px 0 60px;
  position: relative;
  /deep/.my-spectrogram-carousel {
    width: 100%;
    .spectrogram-img {
      width: 100%;
      height: 100%;
    }
    .el-carousel__indicators {
      bottom: 45px;
    }
  }
  .tips {
    width: 460px;
    min-height: 50px;
    border-radius: 10px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 80px;
    z-index: 999;
  }
}
</style>
